var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c("MainToolbar", {
        ref: "mainToolbar",
        on: { "toggle-drawer": _vm.toggleDrawer },
      }),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            clipped: "",
            app: "",
            "hide-overlay": "",
            "disable-resize-watcher": "",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(
              _vm.drawerSections.filter((a) => _vm.isAuth(a)),
              function (section, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    section.label
                      ? _c(
                          "v-subheader",
                          { staticClass: "text-overline primary--text" },
                          [_vm._v(" " + _vm._s(section.label) + " ")]
                        )
                      : _vm._e(),
                    _vm._l(section.menuTree, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "", to: { path: item.link } },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _c("v-divider", { staticClass: "ma-0" }),
                  ],
                  2
                )
              }
            ),
            0
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [
          _c("router-view", {
            ref: "route",
            staticClass: "pa-2",
            on: { "clear-search": _vm.clearFastSearch },
          }),
        ],
        1
      ),
      _c("DashboardFooter", {
        class: _vm.isProduction ? "primary" : "rossoSacmi",
      }),
      _c("snackbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }