import { usersService } from '@/services/users.service'
import { authorizationsService } from '@/services/authorizations.service'
import { userParametersService } from '@/services/userParameters.service'
import { router } from '@/router'

const state = {
  user: null,
  userParameters: {},
  userAuthorizations: [],
  isAdmin: false,
  internalVisibilityAuth: null,
  filtersAreOpen: false
}

const actions = {
  async login({ dispatch, commit }, azureUser) {
    authorizationsService.getUserAuthorizations()
    .then((auths) => {
      commit('setUserAuthorizations', auths)
      commit('setInternalVisibilityAuth', auths.includes('AUTH_DISPLAY_INTERNAL_DATA'))
    })

    let user
    try {
      user = await usersService.findByAzureAccountOrEmail(azureUser.accountIdentifier, azureUser.userName)
    } catch (error) {
      let newUser = {
        id: null,
        username: azureUser.userName,
        email: azureUser.userName,
        firstName: azureUser.name.split(' ')[0],
        lastName: azureUser.name.split(' ')[1],
        azureAccountId: azureUser.accountIdentifier,
      }
      router.push({ name: 'newUserLanding' })
      user = await usersService.createUserFirstAccess(newUser)
    }

    commit('loginSuccess', user)
    commit('setAdminStatus', user.groups.some(g => g.groupDes === 'GR_ADMINISTRATORS'))

    if (!user.azureAccountId) {
      user.azureAccountId = azureUser.accountIdentifier
      usersService.updateUser(user.id, user).then((resp) => (user = resp))
    }

    return userParametersService
      .getUserParameter(user.id, 'LANGUAGE')
      .then((lang) => {
        if (lang.length == 0) {
          let userParam = {
            id: null,
            userId: user.id,
            key: 'LANGUAGE',
            valueType: 'STRING',
            value: 'en',
          }
          commit('setLanguage', userParam)
          userParametersService.createUserParameter(userParam).then((res) => {
            commit('setLanguage', res)
          })
        } else commit('setLanguage', lang[0])
      })
  },
}

const mutations = {
  loginSuccess(state, user) {
    state.user = user
  },
  setAdminStatus(state, isAdmin) {
    state.isAdmin = isAdmin
  },
  setLanguage(state, language) {
    state.userParameters.language = language
  },
  setUserAuthorizations(state, auths){
    state.userAuthorizations = auths
  },
  setInternalVisibilityAuth(state, auth) {
    state.internalVisibilityAuth = auth
  },
  setFiltersAreOpen(state, filtersAreOpen) {
    state.filtersAreOpen = filtersAreOpen
  },
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
}
