import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const mailService = {
  sendMail,
  getConversation,
  updateInbox,
  createReplyAll,
  updateMail,
  send,
  deleteMail,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/mail`

function sendMail(email) {
  console.log('Send email: ', email)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      to: email.to,
      cc: email.cc,
      ccn: email.ccn,
      from: 'federative@office365.com',
      text: email.text,
      subject: email.subject,
      attachments: email.attachments,
    }),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function getConversation(tag, documentNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/conversation/${tag}/${documentNbr}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function updateInbox() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/updateInbox`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createReplyAll(messageId, comment) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'text/plain' },
    body: comment,
  }
  return fetch(
    resourceUrl + `/createReplyAll/${messageId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function updateMail(messageId, email) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      to: email.to,
      cc: email.cc,
      ccn: email.ccn,
      from: email.from,
      text: email.text,
      subject: email.subject,
      attachments: email.attachments,
    }),
  }
  return fetch(resourceUrl + `/updateMail/${messageId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function send(messageId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/send/${messageId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteMail(messageId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/deleteMail/${messageId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
