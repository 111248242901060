<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card class="pa-2">
              <v-row dense>
                <v-col>
                  <v-row dense>
                    <v-col class="d-inline-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.company')"
                          :value="company ? `${company.supplierName} - ${company.supplierId}` : null"
                          :icon="'mdi-account'"
                        />
                      </v-col>
                    </v-col>
                    <v-col class="d-inline-flex">
                      <v-col class="pa-0" style="min-width: 14rem;">
                        <ReadonlyField
                          :label="'B/L'"
                          :value="pratica.billOfLading"
                          :icon="'mdi-file-document-outline'"
                        />
                      </v-col>
                      <v-col class="pa-0" v-if="isMastersped">
                        <ReadonlyField
                            :label="'Equipment'"
                            :value="getEquipmentReferences(imballi)"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-inline-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.vessel')"
                          :value="pratica.vesselName"
                          :icon="'mdi-ferry'"
                        />
                      </v-col>
                    </v-col>
                    <v-col class="d-inline-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.loadingNode')"
                          :value="pratica.pol ? pratica.pol.locode : ''"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.dischargeNode')"
                          :value="pratica.pod ? pratica.pod.locode : ''"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                  <!-- <v-row dense>
                    <v-col class="d-inline-flex pr-0">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.departure')"
                          :value="etdDate"
                          :icon="'mdi-calendar'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="'PGC'"
                          :value="pgcDate"
                          :icon="'mdi-calendar'"
                        />
                      </v-col>
                    </v-col>
                    <v-col class="d-inline-flex pl-0">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.transitDays')"
                          :value="transitDays"
                        />
                      </v-col>
                    </v-col>
                  </v-row> -->
                </v-col>
                <!-- <v-col>
                  <v-row dense>
                    <v-col class="d-inline-flex">
                      <v-col class="py-0 pl-5">
                        <v-select
                          :label="$t('label.booking')"
                          v-model="selectedBooking"
                          :items="bookings"
                          :menuProps="{ offsetY: true }"
                          hide-details
                        />
                      </v-col>
                      <v-col class="pa-0" />
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-7">
                    <v-col class="d-flex align-end pa-0">
                      <v-col class="pa-0">
                        <ActionsStepper
                          :entity="praticaActions"
                          :props="trasportoPrincipaleFlags"
                          v-on="$listeners"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="accent"
                height="2rem"
                grow
              >
                <v-tab>{{ $t('label.tracking') }}</v-tab>
                <v-tab>{{ $t('label.packages') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card class="px-2 pt-2">
                    <v-row dense>
                      <v-col class="d-inline-flex">
                        <v-col class="pa-0">
                          <ReadonlyField
                            :label="$t('label.departure')"
                            :value="seaTrackingDeparture"
                            :icon="'mdi-calendar'"
                          />
                        </v-col>
                      </v-col>
                      <v-col class="d-inline-flex">
                        <v-col class="pa-0">
                          <ReadonlyField
                            :label="$t('label.arrival')"
                            :value="seaTrackingArrival"
                            :icon="'mdi-calendar'"
                          />
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-0" />
                    <v-data-table
                      :headers="trackingHeadersMare"
                      :items="seaTerminalEvents"
                      :item-class="isDepartedFromPol"
                      :items-per-page="1000"
                      hide-default-footer
                      class="pa-0"
                      dense
                    >
                      <template slot="no-data">
                        <v-icon color="warning" class="mr-1" size="20">
                          mdi-alert
                        </v-icon>
                        <span class="font-weight-medium warning--text">
                          {{ $t('label.noTrackingInfo') }}
                        </span>
                      </template>
                      <template v-slot:item.eventDateTime="{ item }">
                        {{ formatDateShort(item.eventDateTime) }}
                      </template>
                      <template v-slot:item.eventCode="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.eventCode == 'EST' ? 'ESTIMATED'
                              : item.eventCode == 'ACT' ? 'ACTUAL'
                              : item.eventCode == 'PLN' ? 'PLANNED' : '' }}
                        </span>
                      </template>
                      <template v-slot:item.eventName="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.eventName }}
                        </span>
                      </template>
                      <template v-slot:item.eventType="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.eventType }}
                        </span>
                      </template>
                      <template v-slot:item.eventDescription="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.eventDescription }}
                        </span>
                      </template>
                      <!-- <template v-slot:item.emptyIndicator="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.emptyIndicator }}
                        </span>
                      </template> -->
                      <!-- <template v-slot:item.equipmentCode="{ item }">
                        <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                          {{ item.equipmentCode }}
                        </span>
                      </template> -->
                      <template v-slot:item.transportMode="{ item }">
                        <div class="d-flex justify-center">
                          <v-icon v-if="item.transportMode == 'TRUCK'" color="primary">
                            mdi-truck
                          </v-icon>
                          <v-icon v-if="item.transportMode == 'VESSEL' || item.transportMode == '1'" color="primary">
                            mdi-ferry
                          </v-icon>
                          <v-icon v-if="item.transportMode == 'RAIL'" color="primary">
                            mdi-train
                          </v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="px-2">
                    <v-data-table
                      :headers="imballiHeaders"
                      :items="imballi"
                      :search="imballiSearch"
                      :items-per-page="1000"
                      hide-default-footer
                      class="pa-0"
                      dense
                    >
                      <!-- imballiFiltered -->
                      <template v-slot:top>
                        <v-text-field
                          v-model="imballiSearch"
                          :label="$t('label.search')"
                          append-icon="mdi-magnify"
                          class="ma-0 pa-0 text-caption"
                          single-line
                          hide-details
                        />
                      </template>
                      <template slot="no-data">
                        <v-icon color="warning" class="mr-1" size="20">
                          mdi-alert
                        </v-icon>
                        <span class="font-weight-medium warning--text">
                          {{ $t('label.noTrackingInfo') }}
                        </span>
                      </template>
                      <template v-slot:item.transportOrderStatus="{ item }">
                        <span> {{ formatTrasportOrderStatus(item.transportOrderStatus) }} </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="ml-3 px-0">
        <LinkPratica :praticaId="pratica.id" @send-link="loadNewMailTracking" />
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="[]"
          @email-sent="handleNewMailSent"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import Tracking from '@/mixins/Tracking'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
// import ActionsStepper from '@/components/elements/ActionsStepper.vue'
import LinkPratica from '@/components/elements/pratica/LinkPratica.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'

export default {
  name: 'PraticaTrasportoPrincipaleMare',
  props: ['pratica', 'praticaActions', 'imballi', 'company'],
  mixins: [Email, Tracking],
  components: { ReadonlyField, LinkPratica, EmailDialog }, // ActionsStepper,
  data() {
    return {
      tab: 0,
      // selectedBooking: null,
      // imballiFiltered: [],
      trasportoPrincipaleFlags: [
        { text: this.$t('label.bookingRequested'), value: 'bookingRequested' },
        { text: this.$t('label.blRequested'), value: 'blRequested' },
        { text: this.$t('label.stuffing'), value: 'stuffingRequested' },
      ],
      imballiHeaders: [
        { text: this.$t('label.saleOrder'), value: 'saleOrder' },
        { text: this.$t('label.position'), value: 'saleOrderLine' },
        { text: this.$t('label.quantity'), value: 'quantity' },
        { text: this.$t('label.transportState'), value: 'transportOrderStatus' },
        { text: this.$t('label.item'), value: 'itemDescription' },
        { text: this.$t('label.booking'), value: 'bookingNbr' },
      ],
      transitDays: null,
      imballiSearch: null,
    }
  },
  watch: {
    /*selectedBooking(booking) {
      this.imballiFiltered = this.imballi.filter((imballo) => {
        let bkNbr = imballo.bookingNbr
        if (!imballo.bookingNbr || imballo.bookingNbr == '') bkNbr = 'N/A'
        return bkNbr == booking
      })
      this.trackPratica()
    },*/
  },
  methods: {
    init() {
      this.fixPolPodLocode()
      this.getSeaTracking()
      // this.selectedBooking = this.bookings[0]
    },
    formatTrasportOrderStatus(transportOrderStatus) {
      switch (transportOrderStatus) {
        case '@00@':
          return 'Ritiro Creato'
        case '@3U@':
          return 'Ritiro Congelato'
        case '@4A@':
          return 'Ritiro Trasportato'
        case '@8N@':
          return 'Ritiro Congelato'
        case '@8R@':
          return 'Annulla Stampa'
      }
    },
    getEquipmentReferences(imballi) {
      let equipmentReferences = '';
      imballi.forEach((imballo) => {
        equipmentReferences += imballo.equipmentReference + " | "
      })
      equipmentReferences = equipmentReferences.slice(0, -2)
      return equipmentReferences
    }
  },
  created() {
    this.init()
  },
  computed: {
    isMastersped() {
      return process.env.VUE_APP_IS_MSPED_GSCV === 'true'
    }
  }
}
</script>

<style scoped>
.v-simple-checkbox .theme--light.v-icon {
  color: #005eb8 !important;
}
.v-simple-checkbox .v-input--selection-controls__input {
  margin: 0 !important;
}
.v-list--dense .v-subheader {
  height: fit-content !important;
}
.v-list-item {
  min-height: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}
.flex {
  border: solid 2px green;
}*/
</style>
