<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="fill-height ma-0">
      <v-col class="pa-0">
        <v-card class="d-flex fill-height">
          <v-row class="ma-0">
            <v-col class="d-flex flex-column pa-2">
              <div>
                <v-row dense>
                  <v-col class="d-inline-flex">
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.customer')"
                        :value="pratica.client ? pratica.client.customerName : null"
                        :icon="'mdi-account'"
                      />
                    </v-col>
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.consignee')"
                        :value="pratica.consignee ? pratica.consignee.customerName : null"
                        :icon="'mdi-account-check'"
                      />
                    </v-col>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col class="d-inline-flex">
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.userLabel')"
                        :value="pratica.user ? pratica.user.label : null"
                        :icon="'mdi-account-hard-hat'"
                      />
                    </v-col>
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.agent')"
                        :value="pratica.agent ? pratica.agent.customerName : null"
                        :icon="'mdi-account-tie'"
                      />
                    </v-col>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col class="d-inline-flex">
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.shipper')"
                        :value="pratica.shipper ? pratica.shipper.customerName : null"
                        :icon="'mdi-account-arrow-right'"
                      />
                    </v-col>
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.goodsConsignee')"
                        :value="pratica.goodsConsignee ? pratica.goodsConsignee.customerName : null"
                        :icon="'mdi-account-arrow-left'"
                      />
                    </v-col>
                  </v-col>
                </v-row>
                <v-divider class="my-2" />
                <v-row dense>
                  <v-col class="d-inline-flex">
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.shipperInvoiceReference')"
                        :value="pratica.shipperInvoiceReference"
                        :icon="'mdi-file-document-edit-outline'"
                      />
                    </v-col>
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.saleOrder')"
                        :value="pratica.saleOrder"
                        :icon="'mdi-file-sign'"
                      />
                    </v-col>
                    <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.shipperIncoterms')"
                        :value="pratica.shipperIncoterms"
                        :icon="'mdi-file-document-outline'"
                      />
                    </v-col>
                    <!-- <v-col class="pa-0">
                      <ReadonlyField
                        :label="$t('label.incoterms')"
                        :value="pratica.incoterms"
                        :icon="'mdi-file-document-outline'"
                      />
                    </v-col> -->
                  </v-col>
                </v-row>
              </div>

              <v-row dense class="align-end mt-1">
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.createDate')"
                      :value="createDate"
                      :icon="'mdi-calendar'"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.updateDate')"
                      :value="updateDate"
                      :icon="'mdi-calendar'"
                    />
                  </v-col>
                  <v-col />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="d-flex flex-column px-0 py-3" v-if="showCreateBooking && bookingAuth">
              <v-btn icon small class="primary" @click="$emit('open-booking')">
                <v-icon size="17" color="white">mdi-calendar-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col class="pa-0">
              <PraticaActions
                :entity="praticaActions"
                :props="actions"
                v-on="$listeners"
              />
              <v-row class="ma-0">
                <v-col>
                  <v-textarea
                    v-model="praticaActions.internalNotes"
                    @change="$emit('update-props', praticaActions)"
                    label="Notes"
                    rows="2"
                    hide-details
                    clearable
                    auto-grow
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import PraticaActions from '@/components/elements/pratica/PraticaActions.vue'

export default {
  name: 'PraticaInfo',
  props: ['pratica', 'praticaActions', 'company'],
  emits: ['update-props', 'open-booking', 'open-shipping-instruction'],
  components: { ReadonlyField, PraticaActions },
  data() {
    return {
      actions: [
        {
          text: this.$t('label.bookingRequested'),
          value: 'bookingRequested',
          note: 'bookingRequestedNote',
        },
        {
          text: this.$t('label.confirmBookingLoadLoader'),
          value: 'confirmBookingLoadLoader',
          note: 'bookingLoadLoaderNote',
        },
        {
          text: this.$t('label.confirmBookingLoadCustomer'),
          value: 'confirmBookingLoadCustomer',
          note: 'bookingLoadCustomerNote',
        },
        {
          text: this.$t('label.stuffingRequested'),
          value: 'stuffingRequested',
          note: 'stuffingRequestedNote',
        },
        /*{
          text: this.$t('label.customsDeclarationDone'),
          value: 'foreignAgentCustomsDeclarationDone',
          note: 'foreignAgentCustomsDeclarationNote',
        },*/
        {
          text: this.$t('label.vgmDone'),
          value: 'vgmDone',
          note: 'vgmNote',
        },
        {
          text: this.$t('label.blRequested'),
          value: 'blRequested',
          note: 'blRequestedNote',
        },
        {
          text: this.$t('label.customsClearanceStatus'),
          value: 'customsClearanceStatus',
          note: 'customsClearanceStatusNote',
        },
        {
          text: this.$t('label.preAlertDone'),
          value: 'foreignAgentPreAlertDone',
          note: 'foreignAgentPreAlertNote',
        },
        {
          text: this.$t('label.certificateIssueDone'),
          value: 'certificateIssueDone',
          note: 'certificateIssueNote',
        },
        {
          text: this.$t('label.customersDocumentsDone'),
          value: 'customersDocumentsDone',
          note: 'customersDocumentsNote',
        },
        /*{
          text: this.$t('label.deliveryOrderDone'),
          value: 'foreignAgentDeliveryOrderDone',
          note: 'foreignAgentDeliveryOrderNote',
        },
        {
          text: this.$t('label.podDone'),
          value: 'foreignAgentPodDone',
          note: 'foreignAgentPodNote',
        },*/
      ],
    }
  },
  computed: {
    ...mapState('account', ['userAuthorizations']),
    createDate() {
      return dateFilter.formatDateDay(this.pratica.documentDate)
    },
    updateDate() {
      return dateFilter.formatDateDay(this.pratica.lastUpdateTS)
    },
    showCreateBooking(){
      return this.company.supplierName && (this.company.supplierName.includes('MAERSK') ||
      (this.company.supplierName.includes('CMA') && this.company.supplierName.includes('CGM')) ||
      (this.company.supplierName.includes('YANG') && this.company.supplierName.includes('MING')))
    },
    bookingAuth(){
      return this.userAuthorizations.includes("AUTH_BOOKING")
    }
  },
  methods: {
    goToBooking() {
      this.$router.push({
        name: 'booking',
        params: { id: this.pratica.id },
      })
    },
  },
}
</script>

<style scoped>
.v-textarea::v-deep textarea {
  line-height: 1.2;
  font-size: smaller;
  font-weight: bold;
}
/*.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px red;
}*/
</style>
