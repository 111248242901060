var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "fit-content" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "primary mt-3",
                    attrs: { fab: "", icon: "", height: "50", width: "50" },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                    _vm._v("mdi-notebook"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pa-2 px-3" }, [
            _c("span", { staticClass: "text-overline primary--text" }, [
              _vm._v(" " + _vm._s(_vm.$t("label.addressesBook")) + " "),
            ]),
          ]),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-container",
            {
              staticClass: "pa-0 pb-3",
              attrs: { fluid: "", "fill-height": "" },
            },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "pa-3 pb-0",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.contacts,
                    "items-per-page": 50,
                    "hide-default-footer": "",
                    dense: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header.actions",
                      fn: function () {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addNew },
                            },
                            [_vm._v("mdi-plus-circle")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                class:
                                  _vm.findIndex(item) === _vm.editedIndex
                                    ? "pa-0"
                                    : "",
                              },
                              [
                                _vm.findIndex(item) === _vm.editedIndex
                                  ? _c("v-text-field", {
                                      attrs: {
                                        autofocus:
                                          _vm.findIndex(item) ===
                                          _vm.contacts.length
                                            ? true
                                            : false,
                                        "hide-details": "",
                                        flat: "",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.editedItem.agent,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editedItem, "agent", $$v)
                                        },
                                        expression: "editedItem.agent",
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s(item.agent))]),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  _vm.findIndex(item) === _vm.editedIndex
                                    ? "pa-0"
                                    : "",
                              },
                              [
                                _vm.findIndex(item) === _vm.editedIndex
                                  ? _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        flat: "",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.editedItem.destination,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editedItem,
                                            "destination",
                                            $$v
                                          )
                                        },
                                        expression: "editedItem.destination",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  _vm.findIndex(item) === _vm.editedIndex
                                    ? "pa-0"
                                    : "",
                              },
                              [
                                _vm.findIndex(item) === _vm.editedIndex
                                  ? _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        flat: "",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.editedItem.country,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editedItem,
                                            "country",
                                            $$v
                                          )
                                        },
                                        expression: "editedItem.country",
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s(item.country))]),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  _vm.findIndex(item) === _vm.editedIndex
                                    ? "pa-0"
                                    : "",
                              },
                              [
                                _vm.findIndex(item) === _vm.editedIndex
                                  ? _c("v-text-field", {
                                      attrs: {
                                        autofocus:
                                          _vm.findIndex(item) ===
                                          _vm.contacts.length
                                            ? false
                                            : true,
                                        "hide-details": "",
                                        flat: "",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.editedItem.emails,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editedItem,
                                            "emails",
                                            $$v
                                          )
                                        },
                                        expression: "editedItem.emails",
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s(item.emails))]),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "px-1" }, [
                              _vm.findIndex(item) === _vm.editedIndex
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-3",
                                          attrs: { color: "red" },
                                          on: { click: _vm.handleCancel },
                                        },
                                        [_vm._v(" mdi-window-close ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "green" },
                                          on: { click: _vm.save },
                                        },
                                        [_vm._v(" mdi-content-save ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-3",
                                          attrs: { color: "green" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-pencil ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-delete ")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("label.emptyAddressBook")) + " "
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "v-snackbar",
                {
                  attrs: { timeout: "2000", color: "primary lighten-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function ({ attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._b(
                              {
                                attrs: { color: "primary", icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.snackbar = false
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-close"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.snackbar,
                    callback: function ($$v) {
                      _vm.snackbar = $$v
                    },
                    expression: "snackbar",
                  },
                },
                [
                  _c("span", { staticClass: "primary--text" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }