<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card class="pa-2">
              <v-row dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="'Controparte dogana'"
                      :value="pratica.customsOperator ? pratica.customsOperator.supplierName : null"
                      :icon="'mdi-account'"
                    />
                  </v-col>
                </v-col>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.email')"
                      :value="customsOperatorDefaultEmail"
                      :icon="'mdi-email'"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <ActionsStepperEnum
                :entity="praticaActions"
                :prop="'customsClearanceStatus'"
                :values="customsStatus"
                v-on="$listeners"
              />
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card v-click-outside="clearNotes">
              <v-data-table
                :headers="headers"
                :items="notes"
                :items-per-page="50"
                :no-data-text="''"
                hide-default-footer
                class="pa-3 pb-0"
                dense
              >
                <template v-slot:header.actions>
                  <v-icon color="secondary" @click="addNote">
                    mdi-plus-circle
                  </v-icon>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="pa-0">
                      <v-text-field v-model="item.mrn" hide-details flat solo />
                    </td>
                    <td class="pa-0">
                      <v-text-field v-model="item.a7" hide-details flat solo />
                    </td>
                    <td class="pa-0">
                      <DatePickerMenu
                        @select-date="(date) => (item.a7Date = date)"
                      />
                    </td>
                    <td class="pa-0">
                      <v-text-field
                        v-model="item.customsClearingCode"
                        hide-details
                        flat
                        solo
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row> -->
        <!-- <td>{{ item.mrn }}</td> -->

        <v-row class="ma-0 mt-3" v-if="messages.length">
          <v-col class="pa-0">
            <div
              v-for="(conversation, index) in messages"
              :key="index"
              :class="messages.length - 1 == index ? 'mb-0' : 'mb-3'"
            >
              <MailPanel
                ref="mailPanel"
                :conversation="conversation"
                @email-sent="handleEmailSent"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="ml-3 px-0">
        <v-btn
          fab
          icon
          height="50"
          width="50"
          class="primary"
          @click="
            panel = null
            loadNewEmailDogana()
          "
        >
          <v-icon color="white" size="30">mdi-email</v-icon>
        </v-btn>
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="customsOperatorContacts"
          @email-sent="handleEmailSent"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import ActionsStepperEnum from '@/components/elements/ActionsStepperEnum.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'
import MailPanel from '@/components/elements/mails/MailPanel.vue'
// import DatePickerMenu from '@/components/elements/DatePickerMenu.vue'
import { emailAddressesService } from '@/services/emailAddresses.service.js'

export default {
  name: 'PraticaTransitarioDoganale',
  props: ['pratica', 'praticaActions'],
  mixins: [Email],
  components: {
    ReadonlyField,
    ActionsStepperEnum,
    EmailDialog,
    MailPanel,
    // DatePickerMenu,
  },
  data() {
    return {
      notes: [],
      emailTag: 'DOGANA',
      customsOperatorDefaultEmail: '',
      customsOperatorContacts: [],

      headers: [
        { text: 'MRN', value: 'mrn', sortable: false, width: '25%' },
        { text: 'A7', value: 'a7', sortable: false, width: '25%' },
        { text: this.$t('label.A7Date'), value: 'a7Date', sortable: false, width: '22%' },
        { text: this.$t('label.customsClearingCode'), value: 'customsClearingCode', sortable: false, width: '25%' },
        { value: 'actions', sortable: false, class: 'pa-0 d-flex justify-center' },
      ],
      customsStatus: [
        { text: this.$t('label.notice'), value: 'PRE_ALERT' },
        { text: this.$t('label.billingRequest'), value: 'NOTIFICATION_MERCHANDISE_READY' },
        { text: this.$t('label.goodsCleared'), value: 'REQUEST_PROCESSED' },
      ],
    }
  },
  methods: {
    init() {
      this.loadCustomsOperatorDefaultEmail()
      this.browseMessages(this.emailTag)
    },
    loadCustomsOperatorDefaultEmail() {
      if (!this.pratica.customsOperator) return
      emailAddressesService
        .getDefaultEmail(this.pratica.customsOperator.addressId)
        .then((resp) => {
          if (resp) {
            this.customsOperatorDefaultEmail = resp.smtpAddr
            this.customsOperatorContacts.push({
              emails: this.customsOperatorDefaultEmail,
            })
          }
        })
    },
    clearNotes() {
      let clearedNotes = []
      this.notes.forEach((note) => {
        if (!Object.values(note).every((x) => x === null || x === '')) {
          clearedNotes.push(note)
        }
      })
      this.notes = clearedNotes
    },
    addNote() {
      if (this.notes.length >= 10) return
      let note = { mrn: '', a7: '', a7Date: '', customsClearingCode: '' }
      this.notes.push(note)
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-list-item {
  min-height: 0 !important;
}
.v-text-field {
  min-width: fit-content;
}

/* .v-data-table::v-deep th {
  border: solid 1px red;
} */
/* .row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
} */
</style>
