import Vue from 'vue'
import Vuex from 'vuex'

import { account } from "./modules/account.module";
import { snackbar } from "./modules/snackbar.module";
import { searchbar } from "./modules/searchbar.module";
import { application } from "./modules/application.module";
import { electronicFlow } from "./modules/electronicFlow.module";
import { booking } from "./modules/booking.module";
import { shippingInstruction } from "./modules/shippingInstruction.module";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        account,
        snackbar,
        searchbar,
        application,
        electronicFlow,
        booking,
        shippingInstruction
    },
    strict: debug
})