<template>
  <v-card class="my-1" outlined>
    <v-row justify="space-around" class="ma-0">
      <v-col cols="auto" class="d-flex flex-column pa-1 py-0">
        <v-row dense class="ma-0">
          <v-col class="py-0 d-flex flex-column">
            <span
              class="text-h6 font-weight-black mb-1"
              v-text="pratica.documentNbr"
            />
            <span
                v-if="isMastersped" v-text="pratica.documentNbrAlt"
            />
            <Semaphore
              :pratica="pratica"
              @show-status="(status) => (generalStatus = status)"
            />
          </v-col>
        </v-row>
        <!-- <v-chip :color="generalStatus" label>
              <v-icon v-if="pratica.saleChannel == 40">mdi-airplane</v-icon>
              <v-icon v-if="pratica.saleChannel == 30">mdi-truck</v-icon>
              <v-icon v-if="pratica.saleChannel == 20">mdi-ferry</v-icon>
              <span class="font-weight-bold ml-3">
                {{ pratica.saleChannel == 40 ? $t("label.plane") : pratica.saleChannel == 30 ? $t("label.road") : $t("label.sea") }}
              </span>
            </v-chip> -->
        <!-- <v-icon left> mdi-label </v-icon>
              {{ $t("upper.inTransit") }} -->
        <v-row dense class="ma-0">
          <v-col class="py-0 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.createDate')"
              :value="createDate"
            />
          </v-col>
          <v-col class="py-0 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.updateDate')"
              :value="updateDate"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <v-col class="pa-1 d-flex align-center" style="max-width: 20rem;">
        <v-flex>
          <v-col class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.shipper')"
              :value="pratica.shipperName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.consignee')"
              :value="pratica.consigneeName"
            />
          </v-col>
        </v-flex>
      </v-col>

      <v-divider vertical />
      <v-col class="pa-1 d-flex align-center" style="max-width: 11rem;">
        <v-flex>
          <v-col class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.saleOrder')"
              :value="pratica.saleOrder"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col v-if="!isMastersped" class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.shipperInvoiceReference')"
              :value="pratica.shipperInvoiceReference"
            />
          </v-col>
          <v-col v-else class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
                :label="$t('label.company')"
                :value="pratica.supplierName"
            />
          </v-col>
        </v-flex>
      </v-col>
      <v-divider vertical />
      <v-col cols="auto" class="pa-1 d-flex align-center" style="width: 12rem;">
        <v-flex>
          <v-col class="py-0 px-1 text-caption font-weight-bold">
            <ReadonlyField
              :label="$t('label.userLabel')"
              :value="pratica.userName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-row class="ma-0">
            <v-col cols="auto" class="py-0 px-1 text-caption font-weight-bold">
              <ReadonlyField
                :label="$t('label.incoterms')"
                :value="pratica.shipperIncoterms"
              />
              <!-- :value="`${pratica.incoterms ? `${pratica.incoterms} / ` : ''} ${pratica.shipperIncoterms ? pratica.shipperIncoterms : ''}`" -->
            </v-col>
            <v-col class="py-0 px-1 ml-2 text-caption font-weight-bold">
              <ReadonlyField :label="$t('label.pol')" :value="pratica.pol" />
            </v-col>
            <v-col class="py-0 px-1 text-caption font-weight-bold">
              <ReadonlyField :label="$t('label.pod')" :value="pratica.pod" />
            </v-col>
          </v-row>
        </v-flex>
      </v-col>
      <v-divider vertical />
      <v-col class="pa-1 d-flex align-center">
        <PraticaStepper :pratica="pratica" :key="pratica.id" />
      </v-col>
      <v-divider vertical />
      <v-col
        cols="auto"
        class="pa-1"
        style="display: grid; align-items: center;"
      >
        <v-row dense class="ma-0">
          <v-col>
            <v-btn
              fab
              depressed
              x-small
              @click="expandImballi = !expandImballi"
            >
              <v-icon>mdi-arrow-expand-vertical</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="ma-0">
          <v-col>
            <v-btn fab depressed x-small @click="goToPratica">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row dense class="ma-0">
          <v-col>
            <v-btn fab depressed x-small @click="goToPraticaNew">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->
      </v-col>
      <v-divider vertical v-if="showSelection" />
      <v-col cols="auto" class="d-flex pa-1" v-if="showSelection">
        <div class="align-self-center" style="max-height: fit-content;">
          <v-checkbox
            v-model="selected"
            @change="addRowToSelection"
            color="primary"
            class="checkbox ma-0 pa-0"
            hide-details
          />
        </div>
      </v-col>
    </v-row>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12" class="pa-0">
        <Imballi :open="expandImballi" :documentNbr="pratica.documentNbr" />
      </v-col>
    </v-row>
    <!-- <v-row justify="space-around" class="ma-0">
      <v-col cols="12" class="pa-0">
        <Details :open="expandDettagli" />
      </v-col>
    </v-row> -->
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import PraticaStepper from '@/components/elements/pratica/PraticaStepper.vue'
import Semaphore from '@/components/elements/Semaphore.vue'
import Imballi from '@/components/elements/Imballi.vue'
// import Details from '@/components/elements/Details.vue'

export default {
  name: 'PraticaRow',
  props: ['pratica', 'currentSelection', 'showSelection'],
  emits: ['select-row', 'add-to-selection'],
  components: { ReadonlyField, PraticaStepper, Semaphore, Imballi }, // Details
  data() {
    return {
      expandImballi: false,
      // expandDettagli: false,
    }
  },
  watch: {
    pratica: {
      handler() {
        this.expandImballi = false
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('account', ['isAdmin', 'internalVisibilityAuth']),
    createDate() {
      return dateFilter.formatDateDayShort(this.pratica.documentDate)
    },
    updateDate() {
      return dateFilter.formatDateDayShort(this.pratica.lastUpdateTS)
    },
    selected() {
      return this.currentSelection.includes(this.pratica)
    },
    isMastersped() {
      return process.env.VUE_APP_IS_MSPED_GSCV === 'true'
    }
  },
  methods: {
    goToPratica() {
      this.$emit('select-row')
      this.$router.push({
        name: 'pratica',
        params: { id: this.pratica.id },
      })
    },
    addRowToSelection() {
      this.$emit('add-to-selection')
    },
  },
}
</script>

<style scoped>
.checkbox::v-deep .v-input--selection-controls__input {
  margin: 0.3rem !important;
}
.checkbox::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
/*.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px green;
}*/
</style>
