import { mapState } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'

export default {
  name: 'Tracking',
  data() {
    return {
      airportEventsHeaders: [
        { text: this.$t('label.phase'), value: 'travelPhaseEnum' },
        { text: this.$t('label.description'), value: 'description' },
        { text: this.$t('label.station'), value: 'station' },
        { text: this.$t('label.planned'), value: 'planned' },
        { text: this.$t('label.effective'), value: 'actual' },
        { text: this.$t('label.replanned'), value: 'isReplanned', sortable: false, align: 'center', width: '5rem' },
      ],
      trackingHeadersAereo: [
        { text: this.$t('label.company'), value: 'carrierCode', sortable: false },
        { text: this.$t('label.flight'), value: 'number', sortable: false },
        { text: this.$t('label.origin'), value: 'origin', sortable: false },
        { text: this.$t('label.destination'), value: 'destination', sortable: false },
        { text: 'Etd', value: 'departureScheduleTime', sortable: false },
        { text: 'Atd', value: 'departureActualTime', sortable: false },
        { text: 'Eta', value: 'arrivalScheduleTime', sortable: false },
        { text: 'Ata', value: 'arrivalActualTime', sortable: false },
        { text: 'Status', value: 'name', sortable: false },
        { text: 'Feeder', value: 'isRoadFeederService', sortable: false },
        { text: this.$t('label.replanned'), value: 'isReplanned', sortable: false, align: 'center', width: '5rem' },
      ],
      alertEventsHeaders: [
        { text: this.$t('label.phase'), value: 'phase' },
        { text: this.$t('label.description'), value: 'description' },
        { text: this.$t('label.station'), value: 'station' },
        { text: this.$t('label.notes'), value: 'notes' },
      ],
      trackingHeadersMare: [
        { text: this.$t('label.dateTime'), value: 'eventDateTime', sortable: false },
        { text: this.$t('label.estAct'), value: 'eventCode', sortable: false },
        { text: this.$t('label.event'), value: 'eventName', sortable: false },
        { text: this.$t('label.equipment'), value: 'equipment', sortable: false },
        { text: this.$t('label.equipmentType'), value: 'equipmentCode', sortable: false },
        { text: this.$t('label.location'), value: 'facilityLocation', sortable: false },
        // { text: this.$t('label.empty'), value: 'emptyIndicator', sortable: false },
        { text: this.$t('label.vessel'), value: 'vesselName', sortable: false },
        { text: this.$t('label.transport'), value: 'transportMode', sortable: false, align: 'center' },
        { text: this.$t('label.description'), value: 'eventDescription', sortable: false },
        { text: this.$t('label.booking'), value: 'booking', sortable: false },
      ],
      captionClass: 'text-caption',
      captionBoldClass: 'text-caption font-weight-bold',
      seaTerminalEvents: [],
      seaTrackingResponse: [],
      seaTrackingEtd: null,
      seaTrackingAtd: null,
      seaTrackingEta: null,
      seaTrackingAta: null,

      headerEtd: null,
      headerAtd: null,
      headerEta: null,
      headerAta: null,
    }
  },
  computed: {
    ...mapState('account', ['user']),
    bookings() {
      if (!this.imballi) return
      let bookingList = []
      this.imballi.forEach((imballo) => {
        let bookingNbr = imballo.bookingNbr && imballo.bookingNbr != '' ? imballo.bookingNbr : 'N/A'
        if (!bookingList.includes(bookingNbr)) bookingList.push(bookingNbr)
      })
      return this.sortBookings(bookingList)
    },
    seaTrackingDeparture() {
      let departure = ''
      if (this.pratica.pol)
        departure = `${this.pratica.pol.locode} - ${this.pratica.pol.countryName}, ${this.pratica.pol.countryId}`
      departure += this.seaTrackingDepartureDate
      return departure
    },
    seaTrackingArrival() {
      let arrival = ''
      if (this.pratica.pod)
        arrival = `${this.pratica.pod.locode} - ${this.pratica.pod.countryName}, ${this.pratica.pod.countryId}`
      arrival += this.seaTrackingArrivalDate
      return arrival
    },
    seaTrackingDepartureDate() {
      let departure = ''
      if (this.seaTrackingAtd) {
        departure += ` ${this.seaTrackingAtd} <span class="text-caption">(Actual)</span>`
      } else if (this.seaTrackingEtd) {
        departure += ` ${this.seaTrackingEtd} <span class="text-caption">(Estimated)</span>`
      }
      return departure
    },
    seaTrackingArrivalDate() {
      let arrival = ''
      if (this.seaTrackingAta) {
        arrival += ` ${this.seaTrackingAta} <span class="text-caption">(Actual)</span>`
      } else if (this.seaTrackingEta) {
        arrival += ` ${this.seaTrackingEta} <span class="text-caption">(Estimated)</span>`
      }
      return arrival
    },
  },
  methods: {
    async getSeaTracking() {
      this.seaTrackingResponse = await this.trackPraticaMare()
      if (!this.seaTrackingResponse) return this.seaTrackingResponse = []
      let terminalEvents = []
      await this.seaTrackingResponse.forEach(async (response) => {
        await this.setBookingToSeaTrackingDetails(response.details, response.bookingNbr)
        terminalEvents = terminalEvents.concat(response.details)

        this.getHeaderDates(response)
      })
      terminalEvents = await this.clearSeaTerminalEvents(terminalEvents)
      this.seaTerminalEvents = await this.sortTrackingDetails(terminalEvents)
      this.extractSeaTrackingDates()
    },
    getHeaderDates(header) {
      if (header.etd) this.headerEtd = header.etd 
      if (header.atd) this.headerAtd = header.atd 
      if (header.eta) this.headerEta = header.eta 
      if (header.ata) this.headerAta = header.ata 
    },
    trackPraticaMare() {
      return praticheService.trackPraticaMare(this.pratica.id, this.pratica.mainTransportCompany)
    },
    extractSeaTrackingDates() {
      let etd, atd, eta, ata

      if (this.headerEtd) etd = this.headerEtd 
      if (this.headerAtd) atd = this.headerAtd 
      if (this.headerEta) eta = this.headerEta 
      if (this.headerAta) ata = this.headerAta 

      let mapArrival = new Map()
      this.seaTerminalEvents.forEach((event) => {
        if (event.facilityLocation == this.pratica.pol.locode && event.eventName == 'DEPA')
          event.eventCode == 'ACT' ? (atd = event.eventDateTime) : (etd = event.eventDateTime)
        if (event.facilityLocation == this.pratica.pod.locode)
          mapArrival.set(event.eventName, event)
      })

      let schedule
      if (mapArrival.get('ARRI')) schedule = mapArrival.get('ARRI')
      else if (mapArrival.get('GTIN')) schedule = mapArrival.get('GTIN')
      if (schedule)
        schedule.eventCode == 'ACT' ? (ata = schedule.eventDateTime) : (eta = schedule.eventDateTime)

      this.seaTrackingEtd = this.formatDate(etd)
      this.seaTrackingEta = this.formatDate(eta)
      this.seaTrackingAtd = this.formatDate(atd)
      this.seaTrackingAta = this.formatDate(ata)
      try {
        let actualDeparture = new Date(atd)
        let actualArrival = new Date(ata)
        this.transitDays = Math.round((actualArrival.getTime() - actualDeparture.getTime()) / (1000 * 60 * 60 * 24))
      } catch (error) {
        console.log('Error extracting transit days: ', error)
      }
    },
    clearSeaTerminalEvents(terminalEvents) {
      let map = new Map()
      for (let event of terminalEvents) {
        map.set(this.getKey(event), event)
      }
      terminalEvents = []
      for (const [key, value] of map) {
        terminalEvents.push(value)
      }
      return terminalEvents
    },
    checkPraticaCompany() {
      return (
        this.pratica.mainTransportCompany &&
        this.pratica.mainTransportCompany.length
      )
    },
    checkBooking(booking) {
      return booking && booking.length > 1 && booking != 'N/A'
    },
    getKey(o) {
      return o.eventDateTime + o.eventName + o.facilityLocation + o.equipment
    },
    sortDatesDesc(list) {
      return list.sort((a, b) => new Date(b) - new Date(a))
    },
    sortBookings(list) {
      return list.sort(function (a, b) {
        if (a > b) return 1
        if (a < b || a == 'N/A') return -1
        return 0
      })
    },
    sortTrackingDetails(list) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a.eventDateTime < b.eventDateTime) return 1
        if (a.eventDateTime > b.eventDateTime) return -1
        return 0
      })
    },
    setBookingToSeaTrackingDetails(details, booking) {
      details.forEach((detail) => {
        detail['booking'] = booking
      })
    },
    isDepartedFromPol: function (item) {
      return item.eventName == 'DEPA' &&
        item.eventCode == 'ACT' &&
        item.facilityLocation == this.pratica.pol.locode
        ? 'font-weight-bold'
        : ''
    },
    fixPolPodLocode() {
      if (this.pratica.pol.locode == 'ITLEG') {
        this.pratica.pol.locode = 'ITLIV'
        this.pratica.pol.label = this.pratica.pol.label.replace('ITLEG', 'ITLIV')
      }
      if (this.pratica.pod.locode == 'ITLEG') {
        this.pratica.pod.locode = 'ITLIV'
        this.pratica.pod.label = this.pratica.pod.label.replace('ITLEG', 'ITLIV')
      }
    },
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
    formatDateShort(value) {
      return dateFilter.formatDateShort(value)
    },
    formatDateDay(value) {
      return dateFilter.formatDateDay(value)
    },
    // async getSeaTracking() {
    //   let trackingResponse = []
    //   let terminalEvents = []
    //   for (let booking of this.bookings) {
    //     let response = await this.trackPraticaMare(booking)
    //     if (response) {
    //       trackingResponse.push(response)
    //       await this.setBookingToSeaTrackingDetails(response.seaDetailResponse, response.bookingNbr)
    //       terminalEvents = terminalEvents.concat(response.seaDetailResponse)
    //     }
    //   }
    //   this.seaTrackingResponse = trackingResponse
    //   this.seaTerminalEvents = terminalEvents

    //   this.extractSeaTrackingDates()
    //   let map = new Map()
    //   for (let event of this.seaTerminalEvents) {
    //     map.set(this.getKey(event), event)
    //   }
    //   this.seaTerminalEvents = []
    //   for (const [key, value] of map) {
    //     this.seaTerminalEvents.push(value)
    //   }
    //   this.seaTerminalEvents = this.sortTrackingDetails(this.seaTerminalEvents)
    // },
    // trackPraticaMare(booking) {
    //   let searchField
    //   if (this.pratica.mainTransportCompany == '0002102155' || this.pratica.mainTransportCompany == '0002102556')
    //     searchField = this.pratica.billOfLading
    //   else {
    //     searchField = booking
    //     if (!this.checkPraticaCompany() || !this.checkBooking(booking)) return
    //   }
    //   return praticheService.trackPraticaMare(searchField, this.pratica.mainTransportCompany)
    // },
    // extractSeaTrackingDates() {
    //   let etd = [],
    //     atd = [],
    //     eta = [],
    //     ata = []
    //   this.seaTrackingResponse.forEach((resp) => {
    //     if (resp.etd) etd.push(resp.etd)
    //     if (resp.eta) eta.push(resp.eta)
    //     if (resp.atd) atd.push(resp.atd)
    //     if (resp.ata) ata.push(resp.ata)
    //   })
    //   if (etd.length)
    //     this.seaTrackingEtd = this.formatDate(this.sortDatesDesc(etd)[0])
    //   if (eta.length)
    //     this.seaTrackingEta = this.formatDate(this.sortDatesDesc(eta)[0])
    //   if (atd.length)
    //     this.seaTrackingAtd = this.formatDate(this.sortDatesDesc(atd)[0])
    //   if (ata.length)
    //     this.seaTrackingAta = this.formatDate(this.sortDatesDesc(ata)[0])
    //   try {
    //     let actualDeparture = new Date(atd[0])
    //     let actualArrival = new Date(ata[0])
    //     this.transitDays = Math.round(
    //       (actualArrival.getTime() - actualDeparture.getTime()) /
    //         (1000 * 60 * 60 * 24),
    //     )
    //   } catch (error) {
    //     console.log('Error extracting transit days: ', error)
    //   }
    // },
  },
}
