<template>
  <v-container class="pa-2" fluid v-if="!linkExpired">
    <v-row class="mb-1" dense>
      <v-col>
        <v-card>
          <v-row class="ma-0 pt-2 px-3" dense v-if="currentRoute != 'pratica'">
            <v-col class="d-flex pa-0">
              <v-col class="d-flex align-center pa-0">
                <span class="text-h5 font-weight-black">
                  {{ pratica.documentNbr }}
                </span>
              </v-col>
              <v-col class="d-flex align-center pa-0 justify-end">
                <span class="text-h5 primary--text font-weight-black">
                  {{ pratica.shipper ? pratica.shipper.customerName : null }}
                </span>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-1" dense>
            <v-col class="d-inline-flex">
              <v-col class="pa-0" style="min-width: 30rem; max-width: 30rem;">
                <ReadonlyField
                  :label="$t('label.company')"
                  :value="returnCompany()"
                  :icon="saleChannel == 'Nave' ? 'mdi-ferry' : 'mdi-airplane'"
                />
              </v-col>
              <v-col class="node-col pa-0">
                <ReadonlyField
                  :label="$t('label.pol')"
                  :value="pratica.pol ? pratica.pol.locode : ''"
                  :icon="'mdi-map-marker'"
                />
              </v-col>
              <v-col class="node-col pa-0">
                <ReadonlyField
                  :label="$t('label.pod')"
                  :value="pratica.pod ? pratica.pod.locode : ''"
                  :icon="'mdi-map-marker'"
                />
              </v-col>
              <v-col class="date-col pa-0">
                <ReadonlyField
                  :label="$t('label.departure')"
                  :value="etdComp"
                  :icon="'mdi-calendar'"
                />
              </v-col>
              <v-col class="date-col pa-0">
                <ReadonlyField
                  :label="$t('label.arrival')"
                  :value="etaComp"
                  :icon="'mdi-calendar'"
                />
              </v-col>
              <v-col class="pa-0">
                <ReadonlyField
                  v-if="pratica.awb"
                  :label="$t('label.awb')"
                  :value="pratica.awb"
                  :icon="'mdi-file-document-outline'"
                />
                <!-- <v-select
                  v-if="saleChannel == 'Nave'"
                  :label="$t('label.booking')"
                  v-model="selectedBooking"
                  :items="bookings"
                  :menuProps="{ offsetY: true }"
                  hide-details
                /> -->
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-1" dense>
      <v-col>
        <v-card width="100%">
          <v-row class="ma-0">
            <v-col
              class="d-flex d-flex align-center pa-3"
              style="display: grid; align-items: center;"
            >
              <PraticaStepperTracking
                :pratica="pratica"
                :status="voyageStatus"
                :etd="etdComp"
                :eta="etaComp"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-1" dense v-if="airportEvents.length">
      <v-col>
        <v-card width="100%">
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-row class="ma-1" dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.status')"
                      :value="trackingAereo.status"
                      :icon="'mdi-map-marker-radius'"
                    />
                  </v-col>
                </v-col>
              </v-row>
              <v-divider class="mt-1 mb-0 mx-2" />
              <v-data-table
                :headers="airportEventsHeaders"
                :items="airportEvents"
                :items-per-page="1000"
                hide-default-footer
                class="px-2 py-0"
                dense
              >
                <!-- :no-data-text="$t('label.noTrackingInfo')" -->
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
                </template>
                <template v-slot:item.planned="{ item }">
                  {{ formatDate(item.planned) }}
                </template>
                <template v-slot:item.actual="{ item }">
                  {{ formatDate(item.actual) }}
                </template>
                <template v-slot:item.isReplanned="{ item }">
                  <v-icon v-if="item.isReplanned" color="primary">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card width="100%">
          <v-row class="ma-0" v-if="trackingEventsAereo.length">
            <v-col class="pa-0">
              <v-data-table
                :headers="trackingHeadersAereo"
                :items="trackingEventsAereo"
                :items-per-page="1000"
                hide-default-footer
                sort-by="departureActualTime"
                :sort-desc="false"
                class="px-2 py-0"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
                </template>
                <template v-slot:item.departureScheduleTime="{ item }">
                  {{ formatDate(item.departureScheduleTime) }}
                </template>
                <template v-slot:item.departureActualTime="{ item }">
                  {{ formatDate(item.departureActualTime) }}
                </template>
                <template v-slot:item.arrivalScheduleTime="{ item }">
                  {{ formatDate(item.arrivalScheduleTime) }}
                </template>
                <template v-slot:item.arrivalActualTime="{ item }">
                  {{ formatDate(item.arrivalActualTime) }}
                </template>
                <template v-slot:item.isReplanned="{ item }">
                  <v-icon v-if="item.isReplanned" color="primary">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="seaTerminalEvents.length">
            <v-col class="pa-0">
              <v-data-table
                :headers="trackingHeadersMare"
                :items="seaTerminalEvents"
                :items-per-page="1000"
                :item-class="isDepartedFromPol"
                hide-default-footer
                class="pa-0"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
                </template>
                <template v-slot:item.eventDateTime="{ item }">
                  {{ formatDateShort(item.eventDateTime) }}
                </template>
                <template v-slot:item.eventCode="{ item }">
                  <span class="text-caption">
                    {{ item.eventCode == "EST" ? "ESTIMATED" : item.eventCode == "ACT" ? "ACTUAL" : item.eventCode == "PLN" ? "PLANNED" : "" }}
                  </span>
                </template>
                <template v-slot:item.eventName="{ item }">
                  <span class="text-caption">
                    {{ item.eventName }}
                  </span>
                </template>
                <template v-slot:item.eventType="{ item }">
                  <span class="text-caption">{{ item.eventType }}</span>
                </template>
                <template v-slot:item.eventDescription="{ item }">
                  <span class="text-caption">
                    {{ item.eventDescription }}
                  </span>
                </template>
                <!-- <template v-slot:item.emptyIndicator="{ item }">
                  <span class="text-caption">
                    {{ item.emptyIndicator }}
                  </span>
                </template> -->
                <!-- <template v-slot:item.equipmentCode="{ item }">
                  <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
                    {{ item.equipmentCode }}
                  </span>
                </template> -->
                <template v-slot:item.transportMode="{ item }">
                  <v-icon v-if="item.transportMode == 'TRUCK'" color="primary">
                    mdi-truck
                  </v-icon>
                  <v-icon v-if="item.transportMode == 'VESSEL'" color="primary">
                    mdi-ferry
                  </v-icon>
                  <v-icon v-if="item.transportMode == 'RAIL'" color="primary">
                    mdi-train
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <LinkExpired v-else />
</template>

<script>
import { mapState } from 'vuex'
import Tracking from '@/mixins/Tracking'
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'
import { imballiService } from '@/services/imballi.service.js'
import { suppliersService } from '@/services/suppliers.service.js'
import { externalTrackingService } from '@/services/externalTracking.service.js'
import LinkExpired from '@/components/elements/LinkExpired.vue'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import PraticaStepperTracking from '@/components/elements/pratica/PraticaStepperTracking.vue'

export default {
  name: 'PraticaExternalTracking',
  props: ['uuid', 'praticaId'],
  mixins: [Tracking],
  components: {
    LinkExpired,
    ReadonlyField,
    PraticaStepperTracking,
  },
  data() {
    return {
      linkExpired: false,
      company: {},
      companyLabel: '',
      pratica: {},
      imballi: [],
      imballiTransport: [],
      transportOrders: [],
      trackingAereo: {},
      airportEvents: [],
      trackingEventsAereo: [],
    }
  },
  computed: {
    ...mapState('account', ['isAdmin']),
    saleChannel() {
      if (!this.pratica) return
      let description
      switch (this.pratica.saleChannel) {
        case '20':
          description = 'Nave'
          break
        case '30':
          description = 'Terra'
          break
        case '40':
          description = 'Aereo'
          break
      }
      return description
    },
    trackingAereoEtd() {
      return dateFilter.formatDate(this.trackingAereo.etdPlanned)
    },
    trackingAereoEta() {
      return dateFilter.formatDate(this.trackingAereo.etaPlanned)
    },
    trackingAereoAtd() {
      return dateFilter.formatDate(this.trackingAereo.etdActual)
    },
    trackingAereoAta() {
      return dateFilter.formatDate(this.trackingAereo.etaActual)
    },
    etdComp() {
      switch (this.pratica.saleChannel) {
        case '20':
          return this.seaTrackingDepartureDate
        case '40':
          return this.trackingAereoAtd
            ? this.trackingAereoAtd +
              ` <span class="text-caption">(Actual)</span>`
            : this.trackingAereoEtd
            ? this.trackingAereoEtd +
              ` <span class="text-caption">(Estimated)</span>`
            : null
      }
      return null
    },
    etaComp() {
      switch (this.pratica.saleChannel) {
        case '20':
          return this.seaTrackingArrivalDate
        case '40':
          return this.trackingAereoAta
            ? this.trackingAereoAta +
              ` <span class="text-caption">(Actual)</span>`
            : this.trackingAereoEta
            ? this.trackingAereoEta +
              ` <span class="text-caption">(Estimated)</span>`
            : null
      }
      return null
    },
    transportOrderStatus() {
      let status = 'ko'
      this.imballiTransport.forEach((imballo) => {
        if (imballo.transportOrderStatus == '@4A@') status = 'transported'
      })
      return status
    },
    trackingStatus() {
      if (this.saleChannel == 'Nave' ? !this.seaTrackingResponse.length : this.objIsEmpty(this.trackingAereo))
        return
      let status = 'ko'
      switch (true) {
        case this.saleChannel == 'Nave' ? this.seaTrackingAta != null : this.trackingAereoAta != null:
          status = 'arrived'
          break
        case this.saleChannel == 'Nave' ? this.seaTrackingAtd != null : this.trackingAereoAtd != null:
          status = 'departed'
          break
      }
      return status
    },
    voyageStatus() {
      return !this.trackingStatus || this.trackingStatus == 'ko' ? this.transportOrderStatus : this.trackingStatus
    },
    currentRoute() {
      return this.$route.name
    },
  },
  watch: {
  },
  methods: {
    init() {
      switch (true) {
        case this.uuid != null:
          this.checkValidity()
          break
        case this.praticaId != null:
          this.loadPratica()
          break
      }
    },
    checkValidity() {
      externalTrackingService.checkValidity(this.uuid).then((resp) => {
        if (!resp) this.linkExpired = true
        this.pratica = resp.pratica
        this.getCompany()
        this.fixPolPodLocode()
        this.loadImballiForPratica()
        this.loadTransportOrders()
      })
    },
    loadPratica() {
      praticheService.getPraticaById(this.praticaId).then((resp) => {
        this.pratica = resp
        if (!this.pratica) return
        this.getCompany()
        this.fixPolPodLocode()
        this.loadImballiForPratica()
        this.loadTransportOrders()
      })
    },
    getCompany() {
      if (!this.pratica.mainTransportCompany || !this.pratica.mainTransportCompany.length) return
      suppliersService
        .getSupplierBySupplierId(this.pratica.mainTransportCompany)
        .then((resp) => {
          if (resp) this.company = resp
        })
    },
    loadImballiForPratica() {
      imballiService
        .getImballiByDocumentNbr(this.pratica.documentNbr)
        .then((resp) => {
          if (resp) this.imballi = resp
          switch (this.pratica.saleChannel) {
            case '40':
              this.trackPraticaAereo()
              break
            case '20':
              this.getSeaTracking()
              break
          }
        })
    },
    getImballiByTransportOrder(transportOrder) {
      if (!transportOrder) return
      imballiService
        .getImballiByPraticaTransportOrder(
          this.pratica.documentNbr,
          transportOrder.transportOrderNbr,
        )
        .then((resp) => {
          this.imballiTransport = resp
        })
    },
    loadTransportOrders() {
      praticheService
        .getTransportOrdersForPratica(this.pratica.documentNbr)
        .then((resp) => {
          if (!resp) return
          this.transportOrders = resp
          this.imballiTransport = []
          this.transportOrders.forEach(async (order) => {
            let response = await this.getImballiByTransportOrder(order)
            if (response && response.length)
              this.imballiTransport = this.imballiTransport.concat(response)
          })
        })
    },
    trackPraticaAereo() {
      praticheService.trackPraticaAereo(this.pratica.id).then((resp) => {
        if (!resp) return
        this.trackingAereo = resp
        this.trackingEventsAereo = resp.routing
        if (resp.lastOriginEvent) {
          resp.lastOriginEvent.travelPhaseEnum = 'ORIGIN'
          this.airportEvents.push(resp.lastOriginEvent)
        }
        if (resp.lastDestinationEvent) {
          resp.lastDestinationEvent.travelPhaseEnum = 'DESTINATION'
          this.airportEvents.push(resp.lastDestinationEvent)
        }
      })
    },
    returnCompany() {
      if (this.pratica.airwayCompany)
        return this.companyLabel = `${this.pratica.airwayCompany ? `${this.pratica.airwayCompany.description} - ${this.pratica.airwayCompany.threeDigitCode}` : ''}`
      else 
        return this.companyLabel = this.company ? `${this.company.supplierName} - ${this.company.supplierId}` : null
    },
    objIsEmpty(object) {
      return Object.keys(object).length == 0
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.v-application .text-overline {
  line-height: 0 !important;
}
.node-col {
  max-width: 8rem !important;
}
.date-col {
  min-width: 15rem !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
