<template>
  <v-app-bar
    :color="isProduction ? 'primary' : 'rossoSacmi'"
    app
    clipped-left
    style="border-bottom: 5px solid #64a70b !important;"
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer" class="white--text" />
    <v-toolbar-title class="text-caption pa-0">
      <v-img v-if="!isDemo && isProduction" src="@/assets/logo-italiansped.png" max-width="150" contain />
      <v-img v-if="!isDemo && !isProduction" src="@/assets/italiansped-logo.png" max-width="150" contain />
      <v-img v-if="isDemo" src="@/assets/logo-cirle-white.png" max-width="100" contain />
    </v-toolbar-title>
    <v-spacer />
    <v-select
      v-if="currentRouteName != 'settings' && currentRouteName != 'newUserLanding'"
      v-model="searchType"
      class="fast-search-type mt-6 mx-5 white--text"
      :label="$t('label.searchType')"
      ref="searchType"
      :items="searchTypeItems"
      item-text="text"
      item-value="value"
      @change="(field) => updateSearchField(field)"
      @click:clear="blurSearchField"
      :menu-props="menuProps"
      clearable
      dense
      dark
    />
    <v-text-field
      v-if="currentRouteName != 'settings' && currentRouteName != 'newUserLanding'"
      :label="$t('label.search')"
      ref="search"
      @input="(search) => updateSearch(search != null ? search.toUpperCase() : search)"
      @click:clear="blurSearch"
      class="fast-search mt-6 white--text"
      color="white"
      clearable
      dense
      dark
    />
    <!-- @keydown.enter="(search) => updateSearch(search != null ? search.toUpperCase() : search)"  -->
    <v-spacer />
    <!-- <v-btn icon small dark class="mx-5">
      <v-icon>mdi-magnify</v-icon>
    </v-btn> 
    <v-divider vertical /> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small dark class="mx-5" v-bind="attrs" v-on="on">
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
      <span class="text-caption">{{ $t('label.contactSupport') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small dark class="mx-5" v-bind="attrs" v-on="on">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <span class="text-caption">{{ $t('label.notifications') }}</span>
    </v-tooltip> -->
    <v-divider vertical />
    <v-tooltip bottom v-if="isAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          dark
          class="mx-5"
          v-bind="attrs"
          v-on="on"
          @click="navigateToSettings"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span class="text-caption">{{ $t('label.settings') }}</span>
    </v-tooltip>
    <v-divider vertical v-if="isAdmin" />
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon small dark class="ml-5" v-on="on" @click="dialog = !dialog">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span class="text-caption">{{ $t('label.accountAndSettings') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="300">
      <v-card style="text-align: center;">
        <v-card-text style="padding: 5px;">
          <div class="font-weight-bold title" style="margin: 3px;">
            {{ userFullname }}
          </div>
          <div style="margin: 2px;">{{ userUsername }}</div>
          <v-row dense>
            <v-col>
              <v-select
                :value="userParameters.language"
                :items="selectLanguageItems"
                :label="$t('label.language')"
                item-text="text"
                item-value="value"
                return-object
                @change="(v) => changeLanguage(v)"
                class="mt-6 mx-2 white--text"
                prepend-icon="mdi-translate"
                dense
                solo
              />
            </v-col>
          </v-row>
          <hr />
          <v-btn @click="logout" style="margin: 3px;" color="primary">
            <v-icon>mdi-logout</v-icon>
            Logout
          </v-btn>
          <!-- <v-btn @click="changeAccount" style="margin: 3px;" color="primary">
            <v-icon>mdi-account-switch</v-icon>
            Change
          </v-btn> -->
          <hr />
          <div>{{ appVersion }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { version } from '../../../package.json'
import { mapState, mapActions, mapMutations } from 'vuex'
import { userParametersService } from '@/services/userParameters.service.js'

export default {
  name: 'MainToolbar',
  emits: ['toggle-drawer'],
  data() {
    return {
      // isProductionEnv: false,
      appVersion: `Version ${version}`,
      dialog: false,
      showDrawer: false,
      searchType: '',
      searchText: null,
      selectLanguageItems: [
        { text: 'Italiano', value: 'it' },
        { text: 'English', value: 'en' },
      ],
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
      },
    }
  },
  computed: {
    ...mapState('account', ['user', 'userParameters', 'isAdmin']),
    ...mapState('searchbar', ['search', 'searchField']),
    ...mapState('application', ['isProduction', 'isDemo']),
    userFullname() {
      return this.$msal.data.user.name
    },
    userUsername() {
      return this.$msal.data.user.userName
    },
    userEmail() {
      return ''
    },
    currentRouteName() {
      return this.$route.name
    },
    searchTypeItems() {
      return [
        { text: this.$t('label.pratica'), value: 'pratica' },
        { text: this.$t('label.booking'), value: 'booking' },
        { text: this.$t('label.awb'), value: 'awb' },
        { text: this.$t('label.accountUser'), value: 'user' },
        { text: this.$t('label.consignee'), value: 'consignee' },
        { text: this.$t('label.client'), value: 'client' },
        { text: this.$t('label.saleOrder'), value: 'saleOrder' },
      ]
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(() => {
        this.navigateToDashboard()
      }, 400)
    },
    searchField() {
      this.navigateToDashboard()
    },
  },
  methods: {
    ...mapActions('application', ['checkIsProd', 'checkIsDemo']),
    ...mapMutations('account', ['setLanguage']),
    ...mapMutations('searchbar', ['updateSearch', 'updateSearchField']),
    logout() {
      this.$msal.signOut()
      this.clearFilterMemories()
    },
    // changeAccount(){},
    clearFilterMemories() {
      this.removeBrowseMemory('DashboardPage')
      this.removeFilterMemory('FiltersDialog')
      this.removeFilterMemory('FiltersCard')
    },
    resetFastSearch() {
      this.$refs.search.reset()
      this.$refs.searchType.reset()
      this.updateSearch(null)
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
      this.$emit('toggle-drawer', this.showDrawer)
    },
    changeLanguage(language) {
      let userParam = {
        id: this.userParameters.language.id,
        userId: this.user.id,
        key: 'LANGUAGE',
        valueType: 'STRING',
        value: language.value,
      }
      this.dialog = false
      return userParametersService
        .updateUserParameter(this.userParameters.language.id, userParam)
        .then((lang) => {
          this.setLanguage(lang)
          this.$i18n.locale = lang.value
        })
    },
    navigateToDashboard() {
      if (this.currentRouteName != 'dashboard')
        this.$router.push({ name: 'dashboard' })
    },
    navigateToSettings() {
      if (this.currentRouteName != 'settings')
        this.$router.push({ name: 'settings' })
    },
    blurSearch() {
      setTimeout(() => {
        this.$refs.search.blur()
      }, 100)
    },
    blurSearchField() {
      setTimeout(() => {
        this.$refs.searchType.blur()
      }, 100)
    },
  },
  created(){
    this.checkIsProd()
    this.checkIsDemo()
  }
}
</script>

<style scoped>
.fast-search::v-deep input {
  text-transform: uppercase;
}
.fast-search-type {
  max-width: 8rem;
}
</style>
