<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card class="pa-2">
              <v-row dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.foreignCorrespondent')"
                      :value="pratica.foreignAgent ? pratica.foreignAgent.supplierName : null"
                      :icon="'mdi-account'"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <ActionsStepper
                :entity="praticaActions"
                :props="foreignAgentFlags"
                v-on="$listeners"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row class="ma-0 mt-3" v-if="messages.length">
          <v-col class="pa-0">
            <div v-for="(conversation, index) in messages" :key="index" :class="messages.length - 1 == index ? 'mb-0' : 'mb-3'">
              <MailPanel ref="mailPanel" :conversation="conversation" @email-sent="handleEmailSent" />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column ml-3 px-0">
        <v-btn
          fab
          icon
          height="50"
          width="50"
          class="primary"
          @click="loadNewEmailForeignAgent"
        >
          <v-icon color="white" size="30">mdi-email</v-icon>
        </v-btn>
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="foreignAgentContacts"
          @email-sent="handleEmailSent"
        />
        <AddressesBook
          :supplier="pratica.foreignAgent"
          :contacts="foreignAgentContacts"
          @create-contact="createForeignAgentContacts"
          @update-contact="updateForeignAgentContacts"
          @delete-contact="deleteForeignAgentContacts"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import ActionsStepper from '@/components/elements/ActionsStepper.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'
import MailPanel from '@/components/elements/mails/MailPanel.vue'
import AddressesBook from '@/components/elements/AddressesBook.vue'
import { foreignAgentContactService } from '@/services/foreignAgentContact.service.js'

export default {
  name: 'PraticaCorrispondenteEstero',
  props: ['pratica', 'praticaActions'],
  mixins: [Email],
  components: {
    ReadonlyField,
    ActionsStepper,
    EmailDialog,
    MailPanel,
    AddressesBook,
  },
  data() {
    return {
      emailTag: 'PREALERT',
      foreignAgentContacts: [],
      openAddressesBook: false,
      foreignAgentFlags: [
        { text: this.$t('label.preAlertDone'), value: 'foreignAgentPreAlertDone' },
        { text: this.$t('label.customsDeclarationDone'), value: 'foreignAgentCustomsDeclarationDone' },
        { text: this.$t('label.deliveryOrderDone'), value: 'foreignAgentDeliveryOrderDone' },
        { text: this.$t('label.podDone'), value: 'foreignAgentPodDone' },
      ],
    }
  },
  methods: {
    init() {
      this.loadForeignAgentContacts()
      this.browseMessages(this.emailTag)
    },
    loadForeignAgentContacts() {
      if (!this.pratica.foreignAgent) return
      foreignAgentContactService
        .getForeignAgentContactForPratica(this.pratica.id)
        .then((resp) => {
          if (resp) this.foreignAgentContacts = resp
        })
    },
    createForeignAgentContacts(contact) {
      foreignAgentContactService
        .createForeignAgentContact(contact)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successCreate')
          this.$emit('open-snackbar', msg, true)
        })
    },
    updateForeignAgentContacts(contact) {
      foreignAgentContactService
        .updateForeignAgentContact(contact.id, contact)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successUpdate')
          this.$emit('open-snackbar', msg, true)
        })
    },
    deleteForeignAgentContacts(contact) {
      foreignAgentContactService
        .deleteForeignAgentContact(contact.id)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successDelete')
          this.$emit('open-snackbar', msg, true)
        })
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
/* .row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
} */
</style>
