<template>
  <v-dialog v-model="dialog" width="fit-content">
    <template v-slot:activator="{ on }">
      <v-btn fab icon height="50" width="50" class="primary mt-3" v-on="on">
        <v-icon color="white" size="30">mdi-notebook</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="pa-2 px-3">
        <span class="text-overline primary--text">
          {{ $t('label.addressesBook') }}
        </span>
      </v-card-title>
      <v-divider class="ma-0" />
      <v-container fluid fill-height class="pa-0 pb-3">
        <v-data-table
          :headers="headers"
          :items="contacts"
          :items-per-page="50"
          hide-default-footer
          class="pa-3 pb-0"
          dense
        >
          <template slot="no-data">
            <span class="font-weight-bold">
              {{ $t('label.emptyAddressBook') }}
            </span>
          </template>
          <template v-slot:header.actions>
            <v-icon color="primary" @click="addNew">mdi-plus-circle</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.agent"
                  :autofocus="
                    findIndex(item) === contacts.length ? true : false
                  "
                  hide-details
                  flat
                  solo
                />
                <span v-else>{{ item.agent }}</span>
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.destination"
                  hide-details
                  flat
                  solo
                />
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.country"
                  hide-details
                  flat
                  solo
                />
                <span v-else>{{ item.country }}</span>
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.emails"
                  :autofocus="
                    findIndex(item) === contacts.length ? false : true
                  "
                  hide-details
                  flat
                  solo
                />
                <span v-else>{{ item.emails }}</span>
              </td>
              <td class="px-1">
                <div v-if="findIndex(item) === editedIndex">
                  <v-icon color="red" class="mr-3" @click="handleCancel">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="green" @click="save">
                    mdi-content-save
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon color="green" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" timeout="2000" color="primary lighten-1">
          <span class="primary--text">{{ message }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddressesBook',
  props: ['supplier', 'contacts'],
  emits: ['update-contact', 'create-contact', 'delete-contact'],
  data() {
    return {
      dialog: false,
      snackbar: false,
      message: '',

      editedIndex: null,
      editedItem: {},
      newItem: {},
      defaultItem: {
        agent: null,
        destination: null,
        country: null,
        emails: null,
      },
      headers: [
        {
          text: this.$t('label.agent'),
          value: 'agent',
          sortable: false,
          width: '15rem',
        },
        {
          text: this.$t('label.destination'),
          value: 'destination',
          sortable: false,
          width: '15rem',
        },
        {
          text: this.$t('label.country'),
          value: 'country',
          sortable: false,
          width: '15rem',
        },
        {
          text: this.$t('label.contacts'),
          value: 'contacts',
          sortable: false,
          width: '20rem',
        },
        {
          value: 'actions',
          sortable: false,
          class: 'text-end pr-1',
        },
      ],
    }
  },
  computed: {
    currentAgent() {
      return this.contacts && this.contacts.length
        ? this.contacts[this.contacts.length - 1]['agent']
        : ''
    },
  },
  watch: {
    dialog(v) {
      if (!v) this.handleCancel()
    },
  },
  methods: {
    findIndex(item) {
      return this.contacts.indexOf(item)
    },
    handleCancel() {
      this.cancel()
      this.close()
    },
    validateEmail(emails) {
      let validated = true
      var emailsPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      let verifiedMail = emailsPattern.test(emails)
      if (!verifiedMail) {
        this.message = this.$t('label.insertValidEmail')
        this.snackbar = true
        validated = false
      }
      return validated
    },
    editItem(item) {
      this.cancel()
      this.editedIndex = this.contacts.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    removeNewItem() {
      this.newItem = {}
      this.contacts.splice(0, 1)
    },
    deleteItem(item) {
      this.cancel()
      const index = this.contacts.indexOf(item)
      this.contacts.splice(index, 1)
      this.$emit('delete-contact', item)
    },
    cancel() {
      if (this.editedIndex === this.findIndex(this.newItem))
        this.removeNewItem()
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = null
      }, 300)
    },
    addNew() {
      this.cancel()
      this.defaultItem.agent = this.supplier.supplierName
      this.newItem = Object.assign({}, this.defaultItem)
      this.contacts.unshift(this.newItem)
      this.editedIndex = this.contacts.indexOf(this.newItem)
      this.editedItem = Object.assign({}, this.newItem)
    },
    save() {
      if (!this.validateEmail(this.editedItem.emails)) return
      Object.assign(this.contacts[this.editedIndex], this.editedItem)
      this.close()
      if (this.editedIndex === this.findIndex(this.newItem))
        this.$emit('create-contact', this.newItem)
      else this.$emit('update-contact', this.newItem)
    },
  },
}
</script>
